import React from 'react';
import Video from '../Video/Video';

import bolt from '../../assets/images/BoltSlinger.png';
import grenades from '../../assets/images/Grenades.png';


//videos URLs from Youtube Ghostleague channel https://www.youtube.com/channel/UC1lP8SSilL8MLOnLfHPDwPQ
const ShowcaseYoutube = 'https://www.youtube.com/watch?v=3Bcu2RVMUtE';
const PreAlphYoutube = 'https://www.youtube.com/watch?v=ahJWhRplHtw';
const PreAlph2Youtube = 'https://www.youtube.com/watch?v=2KEGpqsRItM';

const Gameplay = () => (

  <section className="gameplay section">
    <div className="container text-center">
      <h2 className="text-secondary">NO SHOOTERS</h2>

      {/* First Row */}
      <div className="row justify-content-center mb-5">
        <div className="col-md-6 col-lg-4">
          <img src={grenades} alt="Abilities" />
          <div className="p-2 mb-4">
            <h3 className="mb-2 mt-4">ABILITIES</h3>
            <p className="small">
            Your most versatile tool is your abilities. They come in a variety of looks and shapes. Choose between a plethora of options. They can aid or harm, boost or tingle. Affect a group or someone single.
          </p>
          </div>
        </div>
        <div className="col-md-12 col-lg-8">
          <img src={bolt} alt="BoltSlinger" />
          <div className="row">
            <div className="col-sm-6 p-2 mb-4">
              <h3 className="mb-2 mt-2">BOLTSLINGERS</h3>
              <p className="small">
                The BoltSlingers/grappling hook attaches to other players. Fling them around and throw them off stage. Save a friend from fated death, or fling a team mate to engage.
          </p>
            </div>
            <div className="col-sm-6 p-2 mb-4">
              <h3 className="mb-2 mt-2">CLOSE-QUARTER COMBAT</h3>
              <p className="small">
              In close quarters, you will have the options to punch, block, and evade. A successful punch will harm, and a successful block will aid
          </p>
            </div>

          </div>


        </div>

      </div>

      {/* Second row  */}
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <div className="embed-responsive embed-responsive-4by3">
            <Video url={ShowcaseYoutube} width="100%" height="250" />
          </div>
          <div className="p-2 mb-4">
            <h3 className="mb-2 mt-4">POWER UP!</h3>
            <p className="small">
              Energy is everything. Spend your energy with every move, and make sure to never run out. To refill your energy you can reach a chargepad, collect orbs, spend a charge bomb of your own or one from your teammates.
          </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="embed-responsive embed-responsive-4by3">
            <Video url={PreAlphYoutube} />
          </div>
          <div className="p-2 mb-4">
            <h3 className="mb-2 mt-4">FREEDOM TO SURVIVE</h3>
            <p className="small">
              Swing around with your two grappling hooks, jump with incredible power, climb on any surface, or run at incredible speeds in this physics based world.
          </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="embed-responsive embed-responsive-4by3">
            <Video url={PreAlph2Youtube} />
          </div>

          <div className="p-2 mb-4">
            <h3 className="mb-2 mt-4">RACE MODE</h3>
            <p className="small">
              The Race is the first game mode. Find your checkpoints as fast as possible while gathering Power Orbs. Your time, orbs, and speed will decide your final score. Join as a team or alone.
          </p>
          </div>
        </div>
      </div>
    </div>
  </section>

);
export default Gameplay;
