import React from 'react';

const Review = (props) => {
  const { description, stars, by, at} = props;

  return (
    <div className="review">
          <p className="review-text mb-2 mt-4">"{description}"</p>
          <img className="mb-1" src={stars} alt="5 stars"></img>
          <p className="small mb-4">- {by}, {at}</p>
        </div>
  );
}
export default Review;
