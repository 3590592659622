import React from 'react';

const Terms = () => {
    return (
        <>
           <h3>Terms & Conditions</h3>
           <p>
               While using our Service, we may ask you to provide us with certain personally identifiable information
               that can be used to contact or identify you. Personally identifiable information may include, but is not 
               limited to, your email address("Personal Information"). We collect this information for the purpose of providing the 
               Service, indentifying and communicating with you, responding to your requests/enquiries, and improving our services.
            </p> 
       
            <h3>GDPR</h3>
            <p>
                Your rights You are entitled to:
                In 2018 we received a new Personal Data Act. 
                The Act consists of national rules and the EU Privacy Regulation (GDPR - General Data Protection Regulation). 
                The regulation is a set of rules that apply to all EU / EEA countries. Whereas these following six bullet points links capture the essence of these new regulations.
            </p>
            <ol>
                <li>Transparency in own information </li>
                <li>Personal data correction</li>   
                <li>Deletion of personal data</li>
                <li>Limitation of processing of personal data</li>
                <li>Protest against processing of personal data</li>
                <li>Data portability</li>
            </ol>
            <h4>You have the right to:</h4>
            <ul>
            <li>information about the processing of your personal data and obtain access to the personal data held about you</li>
            <li>ask for incorrect, inaccurate or incomplete personal data to be corrected</li>
            <li>request that personal data be erased when it’s no longer needed or if processing it is unlawful</li>
            <li>object to the processing of your personal data for marketing purposes or on grounds relating to your particular situation</li>
            <li>request the restriction of the processing of your personal data in specific cases</li>
            <li>receive your personal data in a machine-readable format and send it to another controller (‘data portability’)</li>
            <li>request that decisions based on automated processing concerning you or significantly affecting you and based on your personal data are made by natural persons, not only by computers. 
                You also have the right in this case to express your point of view and to contest the decision</li>
            </ul>
            <a href="https://gdpr.eu/eu-gdpr-personal-data/" alt="Gdpr" target="_blank" rel="noopener noreferrer">Read more about GDPR</a>
        </>
    );
};
export default Terms;
