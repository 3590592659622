import React from 'react';
import SignupForm from '../SignupForm/SignupForm';

const MakeItReal = () => (

  <section id="make-it-real" className="section make-it-real bg-light">
    <div className="container text-center">

      <h2 className="mb-1">LETS MAKE THIS REAL!</h2>
      <h2>SIGN UP FOR OUR <span className="blue">KICKSTARTER</span></h2>

   
      <div className="col-md-6 offset-md-3 justify-content-center">
          <p className="mb-4">Sign up for our Kickstarter mailing list. You will recieve an <strong>in-game cosmetic reward</strong> as a thank you, when we launch the game.</p>
            
            <SignupForm tag="submit-2"/>
          </div>

    </div>
  </section>

);
export default MakeItReal;
