import React from 'react';
import {Form, Button} from 'react-bootstrap';
import TermsModal from '../Modals/TermsModal';


const SignupForm = (props) => {
  const [validated, setValidated] = React.useState(false);
  const { tag } = props;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    return <TermsModal />
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} action="https://ext.mnm.as/subscribe.asp" acceptCharset="utf-8" method="post">
      <Form.Control type="hidden" name="f" value="4273" />
      <Form.Control type="hidden" name="k" value="11334" />
      <Form.Control type="hidden" name="a" value="on" />
      <Form.Control type="hidden" name="t" value={tag} />
      <Form.Control type="hidden" name="source" value="embedhtml" />
      <Form.Control type="hidden" name="subscriber_list_ids" value="154102" />
      <Form.Control type="text" className="mnmemailaddress" name="mnmhoneypot" />
      
      <Form.Group controlId="signup-email">
        <Form.Control required type="email" name="email" placeholder="Email Address" />
        <Form.Control.Feedback type="invalid">
          Please provide a valid E-mail.
        </Form.Control.Feedback>
        <Form.Text className="text-left">
          <span className="muted">We will not spam you, and we'll never share your email with anyone else.</span>
        </Form.Text>
      </Form.Group>

      {/* 
      <Form.Group controlId="signup-last-name">
        <Form.Label>First Name</Form.Label>
        <Form.Control required type="text" name="firstname" />
        <Form.Control.Feedback type="invalid">
          Please provide a name.
        </Form.Control.Feedback>
      </Form.Group>
    
      <Form.Group controlId="signup-last-name">
        <Form.Label>Last Name</Form.Label>
        <Form.Control required type="text" name="lastname" />
        <Form.Control.Feedback type="invalid">
          Please provide a name.
        </Form.Control.Feedback>
      </Form.Group>
       */}
      <div className="row">
        <div className="col-md-6 pt-3">
          <Form.Group controlId={"signup-consent-checkbox-" + tag}>
            <Form.Check className="text-left" custom required type="checkbox" name="consent_email" value="1" label={["I agree to the ",<br/>,<TermsModal />] } feedback="You must agree before submitting." />
          </Form.Group>
        </div>
        <div className="text-center my-3 col-md-6">
          <Button className="btn-skewed col" variant="primary" type="submit">
            Submit
          </Button>  
        </div>
      </div>
    </Form>
   );
} 
export default SignupForm;


