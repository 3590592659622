import React from 'react';
import Video from '../Video/Video';

import SignupForm from '../SignupForm/SignupForm';
import logo from '../../assets/svg/logos/ghostleague-title-logo-gradient.svg';

import oculus from '../../assets/images/logo/oculus.png';
import windows from '../../assets/images/logo/windows.png';
import vive from '../../assets/images/logo/vive.png';
//import Earlybird from '../Earlybird/Earlybird';

const ShowcaseYoutube = 'https://www.youtube.com/watch?v=JIZ8qgWtOfQ';



const Hero = () => (
  <section className="hero section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-12">
          <h1 className="mb-1">
            <img className="pt-4" src={logo} alt="Ghost League" />
            <span className="sr-only">Ghost League</span>
          </h1>
          <h2 className="sub-header text-white">Jump - Run - Grapple - Climb - Teleport</h2>
          <p className="text-white intro-text glbody mb-4">Unique movement system, lots of customizations, tactical abilities, character tweaking, ranks to climb, teams to form, races to win, and stories to share.</p>
          <br/>
        </div>
      </div>
      <div className="row">

        {/* Video */}
        <div className="col-xl-6 mb-4">
          <div className="video embed-responsive embed-responsive-4by3">
            <Video url={ShowcaseYoutube} />
          </div>
          {/* <a className="btn btn-secondary btn-skewed" href="https://sidequestvr.com/app/995">Download demo</a> */}
        </div>

        {/* Sign up form */}
        <div className="col-xl-6 mb-4">
          <article className="card">
            <h2 className="mb-3">
              <span className="d-block mb-1">SIGN UP FOR OUR <span className="blue">KICKSTARTER</span><br />GET A <span className="blue">FREE</span> IN-GAME REWARD</span>
            </h2>
            <p className="mb-4">Sign up to receive an <strong>in-game cosmetic reward</strong> when we launch the game, and get a notification when the Kickstarter begins!</p>

            <SignupForm tag="submit-1" />
          </article>
        </div>
      </div>

      {/* last row  */}
      <div className="row justify-content-center">
        <img className="icon" src={oculus} alt="Oculus" />
        <img className="icon" src={vive} alt="Vive" />
        <img className="icon" src={windows} alt="Windows Mixed Reality" />
      </div>
    </div>
  </section>
);

export default Hero;
