import React from 'react';
import getVideoID from 'get-video-id';

const Video = props => {
    const service = getVideoID(props.url).service
    if (service === 'youtube') {
      const id = getVideoID(props.url).id
      return <iframe
        title="YouTubePreview"
        width={props.width}
        height={props.height}
        className="embedded-video embed-responsive-item"
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    } else if (service === 'vimeo') {
      const id = getVideoID(props.url).id
      return <iframe
        className="embedded-video embed-responsive-item"
        title="VimeoPreview"
        width={props.width}
        height={props.height}
        src={`https://player.vimeo.com/video/${id}`}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    } else {
      return <div className="alert alert-danger" role="alert">Can't find video service</div>
    }
  }
export default Video;
