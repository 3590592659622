import React from 'react';
import topLeftQuadrant from '../../assets/images/screenshots/screenshot-top-left-quadrant.jpg';
import topRightQuadrant from '../../assets/images/screenshots/screenshot-top-right-quadrant.jpg';
import bottomLeftQuadrant from '../../assets/images/screenshots/screenshot-bottom-left-quadrant.jpg';
import bottomRightQuadrant from '../../assets/images/screenshots/screenshot-bottom-right-quadrant.jpg';
import topLeftHexagon from '../../assets/images/screenshots/screenshot-top-left-hexagon.jpg';
import topMiddleHexagon from '../../assets/images/screenshots/screenshot-top-middle-hexagon.jpg';
import topRightHexagon from '../../assets/images/screenshots/screenshot-top-right-hexagon.jpg';
import bottomLeftHexagon from '../../assets/images/screenshots/screenshot-bottom-left-hexagon.jpg';
import bottomMiddleHexagon from '../../assets/images/screenshots/screenshot-bottom-middle-hexagon.jpg';
import bottomRightHexagon from '../../assets/images/screenshots/screenshot-bottom-right-hexagon.jpg';

const Screenshots = () => {
  return ( 
    <section id="screenshots" className="screenshots section bg-dark">
      <h2 className="header text-center">PRE-ALPHA SCREENSHOTS</h2>
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-md-6">
            <figure>
              <img src={topLeftQuadrant} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-md-6">
            <figure>
              <img src={topRightQuadrant} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-md-5">
            <figure>
              <img src={bottomLeftQuadrant} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-md-7">
            <figure>
              <img src={bottomRightQuadrant} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-sm-6 col-md-4">
            <figure>
              <img src={topLeftHexagon} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-sm-6 col-md-4">
            <figure>
              <img src={topMiddleHexagon} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-sm-6 col-md-4">
            <figure>
              <img src={topRightHexagon} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-sm-6 col-md-4">
            <figure>
              <img src={bottomLeftHexagon} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-sm-6 col-md-4">
            <figure>
              <img src={bottomMiddleHexagon} alt="Screenshot of the game"/>
            </figure>
          </div>
          <div className="col-sm-6 col-md-4">
            <figure>
              <img src={bottomRightHexagon} alt="Screenshot of the game"/>
            </figure>
          </div>
        </div>
      </div>
    </section>
   );
}
export default Screenshots;
