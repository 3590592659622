import React from 'react';
import { Navbar, Nav } from 'react-bootstrap'
import logo from '../../assets/svg/logos/ghostleague-title-logo-gradient.svg';
import { Link } from 'react-scroll'
import SignupModal from '../Modals/SignupModal';

const Header = () => (
  <Navbar expand="xl" fixed="top">
    <Navbar.Brand href="/">
      <img className="logo-small" src={logo} alt=""/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbar" />
    <Navbar.Collapse id="navbar">
      <Nav className="ml-auto align-items-center">
        <li className="nav-item">
          <Link className="nav-link" activeClass="active" spy={true} hashSpy={true} to="character-classes" href="#character-classes" smooth={true} offset={-55} duration={400}>
            YOUR GHOST
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" activeClass="active" spy={true} hashSpy={true} to="about-us" href="#about-us" smooth={true} offset={-55} duration={400}>
            ABOUT US
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" activeClass="active" spy={true} hashSpy={true} to="ghost-what" href="#ghost-what" smooth={true} offset={-55} duration={400}>
            GHOST LEAGUE
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" activeClass="active" spy={true} hashSpy={true} to="gameplay" href="#gameplay" smooth={true} offset={-55} duration={400}>
            Gameplay
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" activeClass="active" spy={true} hashSpy={true} to="world" href="#world" smooth={true} offset={-55} duration={400}>
            World
          </Link>
        </li>
        <li className="nav-item mr-n2">
          <SignupModal 
            variant="secondary"
            additionalClasses="btn-lg btn-skewed"
            buttonText="KICKSTARTER SIGN UP" />
        </li>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
export default Header;
