import React from 'react';
import battery from '../../assets/svg/icons/battery.svg';
import apple from '../../assets/svg/icons/apple.svg';
import raceTrack from '../../assets/svg/icons/race-track.svg';
import smilies from '../../assets/svg/icons/smilies.svg';

import rank from '../../assets/svg/icons/rank.svg';
import classes from '../../assets/svg/icons/classes.svg';
import wrench from '../../assets/svg/icons/wrench.svg';
import grenades from '../../assets/svg/icons/grenades.svg';



const Introduction = () => (
  <section className="section introduction">
    <div className="container text-center">
      <h2><span className="text-secondary">ABOUT GHOST LEAGUE</span></h2>
      <p className="mb-6 lead glbody">
      Ghost League is a fast-paced VR multiplayer sports game. Play alone, or build a team. 
      Train and change your own class, each with different strengths and weaknesses. 
      Strategize and plan before each match. 
      Overcome each challenge with your grappling hooks, creativity, team mates, and a large variety of tactical abilities.
      </p>

      <div className="row py-4">
        <div className="col-md-6 col-lg-3">
          <div className="p-2 mb-4">
            <img className="gameplay-icon" src={battery} alt="Energy based" />
            <h3>ENERGY BASED<br/>GAMEPLAY</h3>
            <p className="small">
            Conserve your energy. Every move costs precious energy to use and when you’re out, its game over.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="p-2 mb-4">
            <img className="gameplay-icon mr-n2" src={apple} alt="Full physics" /> {/* Negative margin because of optic balance */}
            <h3>FULL<br/>PHYSICS</h3>
            <p className="small">
            Play along with the physics instead of against it, and you will quickly 
            become fluent in your motions, create whacky situations, and feel like a superhuman.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mx-auto">
          <div className="p-2 mb-4">
            <img className="gameplay-icon" src={raceTrack} alt="Different themes" />
            <h3>DIFFERENT<br/>GAME MODES</h3>
            <p className="small">
            Ghost League aims to create a game mode for everyone. From big battles to small races. 
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mx-auto">
          <div className="p-2 mb-4">
            <img className="gameplay-icon" src={smilies} alt="Co-op maps" />
            <h3>CO-OP AND<br/>TEAM PLAY</h3>
            <p className="small">
            Ghost League is a game made for co-op play and team fights. 
            You will have a blast alone, but it will be even more exiting with your friends.
            </p>
          </div>
        </div>
      </div>
      <div className="row py-4">
      <div className="col-md-6 col-lg-3">
          <div className="p-2 mb-4">
            <img className="gameplay-icon" src={grenades} alt="Energy based" />
            <h3>TACTICAL<br/>ABILITIES</h3>
            <p className="small">
            Push your playstyle with an excerpt of the available abilities. Use them to heal or harm, boost or hinder.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="p-2 mb-4">
            <img className="gameplay-icon mr-n2" src={classes} alt="Full physics" /> {/* Negative margin because of optic balance */}
            <h3>FREEDOM TO<br/>CHOOSE</h3>
            <p className="small">
            Tweak your suit to match your playstyle, be it light and agile, heavy and sturdy, or loaded with abilities.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mx-auto">
          <div className="p-2 mb-4">
            <img className="gameplay-icon" src={rank} alt="Different themes" />
            <h3>TITLES AND <br/>RANKS </h3>
            <p className="small">
            Get better titles as you earn experience, and when you git gud, you can start ranking among the best and reach for the top.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mx-auto">
          <div className="p-2 mb-4">
            <img className="gameplay-icon" src={wrench} alt="Co-op maps" />
            <h3>GEAR AND<br/>CUSTOMIZATION</h3>
            <p className="small">
            Customize your suit to look different from others. Customize your gear to suit your playstyle and give yourself different options than your peers.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default Introduction;
