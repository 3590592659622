import React from 'react';
// import Video from '../Video/Video';
import arcon from '../../assets/images/arcon-armor.png';
// const ShowcaseYoutube = 'https://www.youtube.com/watch?v=JIZ8qgWtOfQ';
import fiveStars from '../../assets/svg/five-stars.svg';
import Review from '../Reviews/Review';
import SideQuestMay from '../../assets/images/sidequest-may.jpg';
import SideQuestOctober from '../../assets/images/sidequest-october.jpg';


const Feedback = () => {

  return (
    <section className="section feedback">

      <img className="arcon ml-4 mt-md-n6 mt-n1" alt="Arcon" src={arcon} />

      <div className="container text-center">
        <h2><span className="text-secondary">COMMUNITY FEEDBACK</span></h2>


        <div className="row">
          {/*<div className="col-12 col-md-6">
            <div className="video embed-responsive embed-responsive-4by3">
              <Video url={ShowcaseYoutube} />
            </div>
          </div>
          */}
          <div className="col-12">
            <p className="mb-4 mt-2 text-center glbody">
              The game is currently in the Pre-Alpha stage and we have a test-version out on SideQuest. We are so happy with the great feedback from everyone who has played it and love it. If you have an Oculus Quest you can try it yourself at SideQuest.
            </p>
          </div>
        </div>

        <article className="card mb-3">
          <div className="row">
            
            <div className="col-12 col-md-4">
              <Review 
                description="Did not make me sick...[...]. The level and artwork are beautfiul. I didn't play enough to swing accurately but i imagine with more time i'd be flying to where I want. kudos to the devs"
                stars={fiveStars}
                by="Elhnad"
                at="SideQuest"
                />
            </div>

            <div className="col-12 col-md-4">
              <Review 
                description="[...], great game with tons of potential."
                stars={fiveStars}
                by="Gecko"
                at="SideQuest"
                />

              <Review 
                description="Very fun! [...] Looking forward to seeing more!"
                stars={fiveStars}
                by="Neven"
                at="SideQuest"
                />
            </div>

            <div className="col-12 col-md-4">
              <Review 
                description="This game really deserves more attention"
                stars={fiveStars}
                by="[MBD] Boidoi"
                at="SideQuest"
                />
            </div>
          </div>
        </article>

        <div className="row justify-content-center">
          <div className="col-8 col-sm-6 col-md-4 col-lg-3 m-2 m-lg-3">
            <figure>
              <img src={SideQuestMay} alt="SideQuest Staff-Picks May 2020"></img>
            </figure>
          </div>
          <div className="col-8 col-sm-6 col-md-4 col-lg-3 m-2 m-lg-3">
            <figure>
              <img src={SideQuestOctober} alt="SideQuest Staff-Picks October 2020"></img>
            </figure>
          </div>
        </div>

        <div className="col-12 mt-5">
          <a className="btn btn-primary btn-skewed" href="https://sidequestvr.com/app/995">Ghost League On SideQuest</a>
        </div>
      </div>

    </section>
  );
}
export default Feedback;
