import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import Terms from '../Terms/Terms';

const TermsModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <a href="#terms" onClick={showModal}>
        Terms & Conditions 
      </a>
      <Modal size="lg" show={isOpen} onHide={hideModal} >
        <Modal.Header closeButton className="lead">
          <h2>Terms and Conditions</h2>
        </Modal.Header>
        <Modal.Body>
        <Terms />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default TermsModal;

