import React from 'react';
import torstein from '../../assets/images/employees/torstein.jpg';
import frank from '../../assets/images/employees/frank.jpg';
import sebastian from '../../assets/images/employees/sebastian.jpg';
import eirik from '../../assets/images/employees/eirik.png';
import kim from '../../assets/images/employees/kim.jpg';
import nicholas from '../../assets/images/employees/nicholas.jpg';

const About = () => (

<section id="about-us" className="section about-us">
  <div className="container text-center">

    <h2><span className="text-secondary">OUR STORY</span></h2>
    <p className="lead mb-6 glbody text-center">
    The team behind Ghost League is a bunch of game enthusiasts. Some have experience from the very start of the digital game industry, some have played e-sports professionally, and with our knowledge and competence, we want to make our mark on the world of VR gaming.
    </p>

    <div className="row justify-content-center">
      <div className="col-sm-6 col-lg-4 mb-5">
        <img src={torstein} alt="Torstein" />
        <h3>Torstein</h3>
        <p className="small">
          The lead developer and jack-of-all-trades for Ghost League. He is educated in game-programming and has since worked with Unity and VR technology targeted at business applications. He is a former professional esports player and understands esports from the perspectives of a professional player, a spectator, and a developer.
        </p>
      </div>
      <div className="col-sm-6 col-lg-4 mb-5">
        <img src={frank} alt="Frank" />
        <h3>Frank</h3>
        <p className="small">
          The CEO and lead designer for Ghost League. He has several decades of experience in digital design. He is the CEO of two companies, and he has been passionate about computer games and been playing them longer than most gamers today have been alive.
        </p>
      </div>
      <div className="col-sm-6 col-lg-4 mb-5">
        <img src={nicholas} alt="Nicholas" />
        <h3>Nicholas</h3>
        <p className="small">
          Nicholas is the lead concept artist for Ghost League. He's a collector and coffee alchemist. He spends most of his time drawing or painting. He's a connoisseur and an aspiring creator of sci-fi stories and cosmic horror.
        </p>
      </div>
      <div className="col-sm-6 col-lg-4 mb-5">
        <img src={eirik} alt="Eirik" />
        <h3>Eirik</h3>
        <p className="small">
          Eirik started programming at an early age and inspired by movies like Hackers 1995 and Swordfish he made a botnet out of the school network and was ready to pursue a life in a Russian prison. Thankfully he got interested in game development instead and is now a game developer on Ghost League.
        </p>
      </div>
      <div className="col-sm-6 col-lg-4 mb-5">
        <img src={kim} alt="Kim" />
        <h3>Kim</h3>
        <p className="small">
          Kim is the sound director for Ghost League and a very talented game developer with big AAA games in his records. He has a long record with the game industry, and a genuine interest in sound, music, and making an experience to remember.
        </p>
      </div>
      <div className="col-sm-6 col-lg-4 mb-5">
        <img src={sebastian} alt="Sebastian" />
        <h3>Sebastian</h3>
        <p className="small">
          The man behind the scenes, known for making a lot of short films. He has years of experience in Adobe filmmaking programs and is the leader behind all videos related to Ghost League. He is also a former esports player and has been building computers and playing games since he was a child.
        </p>
      </div>
    </div>
  </div>
</section>

);
export default About;
