import React from 'react';
import CharacterClass from './CharacterClass';

import zypher from '../../assets/images/zypher-action.png';
import arcon from '../../assets/images/arcon-action-2.png';
import tycen from '../../assets/images/tycen-action.png';

import zypherBackground from '../../assets/images/backgrounds/zypher-background.jpg';
import arconBackground from '../../assets/images/backgrounds/arcon-background.jpg';
import tycenBackground from '../../assets/images/backgrounds/tycen-background.jpg';

import zypherArmor from '../../assets/images/armor/zypher.png';
import arconArmor from '../../assets/images/armor/arcon.png';
import tycenArmor from '../../assets/images/armor/tycen.png';

import pandaSymbol from '../../assets/images/square-symbols/panda.png';
import dragonSymbol from '../../assets/images/square-symbols/dragon.png';
import bronzeRankSymbol from '../../assets/images/square-symbols/bronze.png';
import silverRankSymbol from '../../assets/images/square-symbols/silver.png';
import goldRankSymbol from '../../assets/images/square-symbols/gold.png';
import roosterSymbol from '../../assets/images/square-symbols/rooster.png';
import waspSymbol from '../../assets/images/square-symbols/wasp.png';
import hawkSymbol from '../../assets/images/square-symbols/hawk.png';
import elephantSymbol from '../../assets/images/square-symbols/elephant.png';

import nineSymbol from '../../assets/images/square-symbols/9.png';
import twelveSymbol from '../../assets/images/square-symbols/12.png';
import thirtySevenSymbol from '../../assets/images/square-symbols/37.png';

import nade1 from '../../assets/svg/grenades/1.svg';
import nade2 from '../../assets/svg/grenades/2.svg';
import nade3 from '../../assets/svg/grenades/3.svg';
import nade4 from '../../assets/svg/grenades/4.svg';
import nade5 from '../../assets/svg/grenades/5.svg';

import colors1 from '../../assets/images/misc/colors1.svg';
import colors2 from '../../assets/images/misc/colors2.svg';
import colors3 from '../../assets/images/misc/colors3.svg';

const Classes = () => {

  return (
    <section className="section classes">

      <CharacterClass 
        name="Agility Build"
        about="Agility builds give you a lighter and more agile specialization. You will shrink down in size and be able to achieve incredible speed. Be aware, you will easily get knocked down if someone catches you."
        icon={zypher}
        armorIcon={zypherArmor}
        background={zypherBackground}
        grenades = {[nade5, nade2, nade3 ]}
        stats = {[3, 7, 5, 1, 2, 3]}
        symbols = {[pandaSymbol, bronzeRankSymbol, twelveSymbol, waspSymbol]}
        colors={colors3}
        mainColor="#7AADC3"
        />


      <CharacterClass 
        name="Utility Build"
        about="Utility builds give you more options with your abilities and a healthy mix of rigidity and speed. With this build you will be the backbone of your team. Both on offence and defence."
        icon={arcon}
        armorIcon={arconArmor}
        background={arconBackground}
        grenades = {[nade1, nade2, nade3]}
        stats = {[7, 4, 4, 3, 4, 3]}
        symbols = {[roosterSymbol, silverRankSymbol, nineSymbol, dragonSymbol]}
        colors={colors1}
        mainColor="#80C37A"
        />

        
      <CharacterClass 
        name="Power Build"
        about="Power builds will grow your size, and turn you into a force to reckon with. Speed is not your virtue but you make up for it with your strength and rigidity."
        icon={tycen}
        armorIcon={tycenArmor}
        background={tycenBackground}
        grenades = {[nade4, nade2, nade5]}
        stats = {[4, 2, 3, 5, 7, 4]}
        symbols = {[hawkSymbol, goldRankSymbol, thirtySevenSymbol, elephantSymbol]}
        colors={colors2}
        mainColor="#C3817A"
        />
    </section>
  );
}
export default Classes;
