import React from 'react';
import SignupForm from '../SignupForm/SignupForm';

const Together = () => (

  <section id="together" className="do-it-together mt-4">
    <div className="container text-center">

      <h2 className="text-secondary">WE NEED TO TO THIS TOGETHER</h2>
   
      <div className="col-md-6 offset-md-3 justify-content-center">
          <p className="mb-4">Sign up for our Kickstarter mailing list. You will recieve an <strong>in-game cosmetic reward</strong> as a thank you, when we launch the game.</p>
            
            <SignupForm tag="submit-5"/>
          </div>

    </div>
  </section>

);
export default Together;
