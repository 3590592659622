import React from 'react';
import SignupForm from '../SignupForm/SignupForm';
import chicken from '../../assets/svg/icons/chicken.svg';

const Earlybird = () => (

  <section id="earlybird" className="section early-bird">
    <div className="container text-center">

      <img class="early-bird-logo mb-2" src={chicken} alt="Early bird" />
      <h2 className="mb-4 blue">EARLY BIRD!</h2>
      <p className="mb-4 glbody">Sign up for our Kickstarter to receive an email when the campaign launches, to secure the early bird plegdes. This means more cool stuff for less money, but only for a very limited time. Signing up will also award you an in-game cosmetic reward as a thank you</p>
   
      <div className="col-md-6 offset-md-3 justify-content-center">
          <p className="mb-4">Sign up for our Kickstarter mailing list. You will recieve an <strong>in-game cosmetic reward</strong> as a thank you, when we launch the game.</p>
            <SignupForm tag="submit-3"/>
          </div>

    </div>
  </section>

);
export default Earlybird;
