import React from 'react';
import Review from './Review';
import fiveStars from '../../assets/svg/five-stars.svg';


const Reviews = () => {
  return (
    <section className="section reviews">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-sm-6">
            <Review 
              description="This was surprisingly good. Highly recommended! [...] As much fun as it was in singleplayer, I can see this being quite fun with friends. Looking forward to further development! Nice work."
              stars={fiveStars}
              by="Bkcs"
              at="SideQuest"
              />
          </div>

          <div className="col-lg-3 col-sm-6">
            <Review 
              description="Incredibly promising early preview /showcase of mechanics!"
              stars={fiveStars}
              by="Coconutbutt"
              at="SideQuest"
              />
          </div>
          
          <div className="col-lg-3 col-sm-6">
            <Review 
              description="This game gives me the experience i didn't even know i wanted, and i love it for that"
              stars={fiveStars}
              by="Hayden"
              at="SideQuest"
              />
          </div>

          <div className="col-lg-3 col-sm-6">
            <Review 
              description="This game is great. The movement mechanics are unique, and executed very well. [...] For a pre-alpha demo, this game feels polished and the level design is great. If you dont get motion sick, there is no reason not to download this!"
              stars={fiveStars}
              by="SaberQuest"
              at="SideQuest"
              />
          </div>

        </div>
      </div>
    </section>
  );
}
export default Reviews;
