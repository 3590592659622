import React from 'react';

import offenceTree from '../../assets/images/talents/offence-clean.png';
import supportTree from '../../assets/images/talents/support-clean.png';
import defenceTree from '../../assets/images/talents/defence-clean.png';

const TalentTree = () => (

  <section id="talent-tree" className="section talent-tree row no-gutters">

    <div className="text-center coming-soon-banner col-12">
      <h2 className="text-uppercase mt-4 mb-2">Talents</h2>
      <p className="mb-4">
        We plan to implement a Talent Tree for Ghost League in the future. You will get alot more to thinker with for your ultimate build.
        <br/>You as a player will have the opportunity to be a part of this develoment if you join our social media channels and talk to us.
      </p>
    </div>
    <div className="d-flex justify-content-center">
      <figure className="tree">
      <img src={offenceTree} alt="Tree 1"/>
      </figure>
      <figure className="tree">
      <img src={supportTree} alt="Tree 2"/>
      </figure>
      <figure className="tree">
      <img src={defenceTree} alt="Tree 3"/>
      </figure>
    </div>

  </section>

);
export default TalentTree;
