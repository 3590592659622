import React from 'react';
import grapplingHookConcept from '../../assets/images/concept-art/grappling-hook-concept.png';
import xlayerArmor from '../../assets/images/concept-art/grey-armor-group.jpg';
import xlayerArnorDrawing from  '../../assets/images/concept-art/armor-drawing.jpg';
import bolts1 from  '../../assets/images/concept-art/boltslinger-1.jpg';
import bolts2 from  '../../assets/images/concept-art/boltslinger-2.jpg';
import bolts3 from  '../../assets/images/concept-art/boltslinger-3.jpg';
import grenades from  '../../assets/images/concept-art/grenades.jpg';

const ConceptArt = () => {
  return ( 
    <section className="section">
      <div className="container">
        <h2 className="text-center">
          <span className="text-secondary">CONCEPT</span> ART
        </h2>
        <p className="font-italic text-center">(From our very talented Lead Concept artist Nicholas)</p>
        <h1 className="text-center mb-4 mt-4 pt-4">GHOST SUITS</h1>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <img className="img-fluid" src={grapplingHookConcept} alt="Grappling hook concept art" />
          </div>
          {/* <div className="col-md-6 d-flex flex-column justify-content-center">
            <article>
              <p className="pb-5">
              A fighter that size couldn't get this deep into space on its own. Well, he ain't going to be 
              around long enough to tell anyone about us. Look at him. He's headed for that small moon. 
              I think I can get him before he gets there...he's almost in range. That's no moon! It's a space station. 
              It's too big to be a space station. I have a very bad feeling about this. 
              </p>
              <p>
              Yes. Our scout ships have reached Dantooine. They found the remains of a Rebel base, but they estimate that it 
              has been deserted for some time. They are now conducting an extensive search of the surrounding systems. She lied! 
              She lied to us! I told you she would never consciously betray the Rebellion. Terminate her...immediately!
              </p>
            </article>
          </div> */}
        </div>
        
        <img className="img-fluid" src={xlayerArmor} alt="Armor concept art" />
        <img className="img-fluid" src={xlayerArnorDrawing} alt="Armor concept art" />
        
      <h1 className="text-center mb-4 mt-4 pb-4 pt-4">BOLTSLINGERS AND ABILITY CONTAINERS</h1>
      <div className="row">
        <div className="col-12 col-md-8">
          <img className="img-fluid" src={bolts1} alt="Armor concept art" />
        </div>
        <div className="col-12 col-md-4">
          <img className="img-fluid" src={grenades} alt="Armor concept art" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <img className="img-fluid" src={bolts2} alt="Armor concept art" />
        </div>
        <div className="col-12 col-md-6">
          <img className="img-fluid" src={bolts3} alt="Armor concept art" />
        </div>
      </div>
      </div>
    </section>
  );
}
 
export default ConceptArt;
