import React from 'react';
import UpaTriangle from '../../assets/svg/upa-triangle-clean.svg';

const GhostSuit = () => (

  <section id="ghost-suit" className="section ghost-suit bg-light">
    <div className="container text-center">
      <h2 className="mb-4 text-secondary"> TWEAK YOUR GHOST SUIT</h2>
      <p className="mb-4 glbody">Adjust your stats by dragging your spec point to the desired location. The closer you are to each corner, the stronger your specialization in its direction will be. As you level up, you will be able to specialize further than before.</p>
      <img className="upa-triangle" src={UpaTriangle} alt="UPA Triangle"/>
    </div>

    
  </section>

);
export default GhostSuit;
