import React from 'react';
import ghostBrawl from '../../assets/svg/icons/person-mountain.svg';
import raceTrack from '../../assets/svg/icons/race-track.svg';
//import ghostMore from '../../assets/svg/icons/person-crosshair.svg';
import ghostEditor from '../../assets/svg/icons/crane.svg';


const GameModes = () => (
  <section className="section text-center gamemodes">
    <div className="container">
      <h2>GAME MODES</h2>
      <p className="glbody text-white mb-5">
      The game will launch with a racing / platformer game mode, <br/>but we have no plans to stop there.
      </p>
      <div className="row">
        
        <div className="col-lg-4 mb-5">
          <div className="card at-launch">
            <div className="card-header">
              <p>AT LAUNCH</p>
            </div>
            <div className="card-body text-center">
              <img className="icon" src={raceTrack} alt="" />
              <h3>GHOST RACE</h3>
              <p className="text-left small"> Ghost Race is all about fluent movement. Grapple, run, and jump from start to finish, and keep up that fluid motion. Earn the Ghost Medal by beating the level creator. Play with your friends for more fun and action. Use your team-mates to boost yourself even faster forward. Cripple your opponents using all the tools at your disposal and go for the win. 
              </p>
            </div>
          </div>
        </div>
        
        <div className="col-lg-4 mb-5">
          <div className="card strech-goal">
            <div className="card-header">
              <p>COMING...</p>
            </div>
            <div className="card-body text-center">
              <img className="icon" src={ghostBrawl} alt="" />
              <h3>GHOST BRAWL</h3>
              <p className="text-left small">
              Ghost Brawl is all about tactics and quick decisions. Keep your own energy from running out, and make sure your opponents can’t. Use your movement, punches, and abilities to stay alive and defeat your opponents.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 mb-5">
          <div className="card strech-goal">
            <div className="card-header">
              <p>COMING...</p>
            </div>
            <div className="card-body text-center">
              <img className="icon" src={ghostEditor} alt="" />
              <h3>LEVEL EDITOR</h3>
              <p className="text-left small">
              You will eventually be able to make your own level designs in Ghost League, in VR. You will be able to lay out some fun and interesting designs for both you and others to play. 
              </p>
            </div>
          </div>
        </div>

      </div>
      
      <div>
        <h2 className="text-secondary">AND MORE TO COME...</h2>
        <p className="text-white glbody">We are still in the early development of the game and therefore more skins, maps, abilities, etc, will come after a succesful kickstarter.</p>

      </div>

    </div>
  </section>
);
export default GameModes;
