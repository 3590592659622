import React from 'react';

const CharacterClass = (props) => {

  const { name, icon, armorIcon, about, grenades, stats, symbols, colors, mainColor} = props;
  const grenadeView = grenades.map((g, index) => <img alt="grenade" className="grenade" src={g} key={index}/>)
  const statTitles = ["Abilities", "Speed", "Reach", "Size", "Power", "Energy"];

  const symbolTitlesW1 = ["Team",   "Rank",   "Your",   "Personal"];
  const symbolTitlesW2 = ["Symbol", "Badge",  "Number", "Symbol"];

  const renderSlots = (num, index) => {
    return Array(num).fill(1).map((_, i) => <div className="skill-slot" style={{backgroundColor: mainColor}} key={`stats-${index}-${i}`} />)
  }

  const statsView = stats.map((n, index) =>
    <div className="row stats-row" key={`stats-${name}-row-${index}-${n}`}>
      <div className="col-4">
        <p className="stat-text text-uppercase">{ statTitles[index] }</p>
      </div>
      <div className="col-7">
        <div className="row">
        { renderSlots(n, index)}
        </div>
      </div>
    </div>)

    const symbolView = symbols.map((s, index) => {
      return (
        <div className="col-3" key={`symbol-${index}`}>
          <div className="d-flex flex-column justify-content-between mr-2 mr-md-4">
            <div className="mb-2">
              <div className="symbol-holder">
                <img src={s} alt="symbol" key={`${name}-symbol-${index}`} />
              </div>
            </div>
            <div className="mb-4 symbol-title">
              <p className="text-uppercase text-center">{symbolTitlesW1[index]} <br/> {symbolTitlesW2[index]} </p>
            </div>
          </div>
      </div>
      )
    });

  return (
    <section className="section character-class mb-0 mt-0 " style={{ "backgroundImage": `url(${props.background})` }}>
      <img className="character-model ml-4 mt-md-n6" alt="Icon" src={icon} />
      <div className="row mt-5 mr-0 mt-sm-0">
        <div className="col-sm-10 offset-sm-2 col-md-7 offset-md-5 text-left">

          {/* About */}
          <div className="mb-4 intro-text">
            <h2 className="text-left text-uppercase mt-4 mb-4">{name}</h2>
            <p className="text-left glbody">{about}</p>
          </div>

          {/* Abilities, Stats, and Colors */}
          <div className="row">

            {/* Abilities and Stats */}
            <div className="col-xl-6">
              
              {/* Abilities */}
              <div className="mb-4 mt-4 ml-0">
                <span class="d-flex flex-column flex-sm-row">
                  <strong class="text-uppercase d-block">Ability Slots</strong> 
                  <p class="ml-sm-4 ml-0 d-block muted"> Choose 3 abilities</p>
                </span>
                <div className="grenades ml-0">
                  {grenadeView}
                </div>
              </div>

              {/* Stats */}
              <div className="ml-0">
                <span className="mb-1 d-inline-flex">
                  <strong className="text-uppercase">Stats</strong>
                </span>
                {statsView}
              </div>
            </div>
            
            {/* Colors */}
            <div className="col-xl-6">
              <div class="justify-content-start">
                <img class="d-block armor-icon" alt="Icon" src={armorIcon} />
                <div class="character-colors">
                    <img class="mx-auto d-block" src={colors} alt="Colors" />
                    <p class="muted mt-1 text-center">(examples of color combination)</p>
                </div>
              </div>
            </div>
          </div>
        
          {/* Customization */}
          <div className="row">
            <div className="col mt-4 mb-4">
              <strong className="text-uppercase">Customization</strong>
              <div className="row no-gutters my-2 symbol-row">
                  { symbolView }
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}
export default CharacterClass;
