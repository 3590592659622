import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import Disclaimer from '../Disclaimer/Disclaimer';


const DisclaimerModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

	return (
		<>
      <a href="#disclaimer" onClick={showModal}>
        Disclaimer, 
      </a>

      <Modal size="l" show={isOpen} onHide={hideModal} >
        <Modal.Header closeButton className="lead">
          <h3>Disclaimer</h3>
        </Modal.Header>
        <Modal.Body>
        <Disclaimer />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
		</>
	);
}
export default DisclaimerModal;
