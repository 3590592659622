import React from 'react';

const Disclaimer = () => {
    return (
        <div>
            <h3>GhostLeague Disclaimer</h3>
            <p>Play this game at your own risk! if you experience excessive fatigue, nausea,
                shortness of breath, chest tightness, dizziness, muscle soreness, discomfort or 
                pain - stop immediately, consult a doctor and rest. Ghost League&trade; should not
                be used to diagnose, treat, or prevent any disease or medical condition. Please read 
                health and safety warnings in your VR headset instruction manual before playing 
                Ghost League&trade;. Seeing as you may be encouraged to perform intense action with your
                controllers, make sure to review your surroundings and clear any obstacles in your 
                play area before starting the game. Always remember to stay in the center of the cleared 
                play area and do not walk around. Playing Ghost League&trade; requires high physical acitivity,
                so follow the same habits as doing a regular workout adapted to your personal capacity. 
                Dont't forget to stretch before playing.
            </p>
        </div>
    );
};
export default Disclaimer;