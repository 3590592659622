import React from 'react';
import Navigation from './Navigation/Navigation';
import Hero from './Hero/Hero';
import Introduction from './Introduction/Introduction';
import Feedback from './CommunityFeedback/Feedback';
// import Ghost from './Ghost/Ghost';
import Gameplay from './Gameplay/Gameplay';
import Gamemodes from './Gamemodes/Gamemodes';
//import Storybox from './Storybox/Storybox';
import Spaceship from './Spaceship/Spaceship';
import About from './About/About';
import Footer from './Footer/Footer';
// import CompetativeGameplay from './CompetativeGameplay/CompetativeGameplay';
import ConceptArt from './ConceptArt/ConceptArt';
import Screenshots from './Screenshots/Screenshots';
import Analytics from './Analytics/Analytics';
import MakeItReal from './MakeItReal/MakeItReal';
import GhostSuit from './GhostSuit/GhostSuit';
import Classes from './Classes/Classes';
import ScreenshotRow from './ScreenshotRow/ScreenshotRow';
import Earlybird from './Earlybird/Earlybird';
import Together from './Together/Together';
import Reviews from './Reviews/Reviews';
import TalentTree from './TalentTree/TalentTree';

const GhostLeague = () => {
	return (
		<>
			<Navigation />
			<Hero />
			<section id="reviews">
				<Reviews />
			</section>
			<section id="ghost-suit">
				<GhostSuit />
			</section>
			<section id="character-classes">
				<Classes />
			</section>
			<section id="talent-tree">
				<TalentTree />
			</section>
			<section id="make-it-real">
				<MakeItReal />
			</section>
			<section id="about-us">
				<About />
			</section>
			<section id="feedback">
				<Feedback />
			</section>
			<section id="ghost-what">
				<Introduction />
			</section>
			<section id="screenshot-row">
				<ScreenshotRow />
			</section>
			<section id="gameplay">
				<Gameplay />
				<Earlybird />
				{/* <CompetativeGameplay /> */}
				<Gamemodes />
			</section>
			<section id="world">
				<Spaceship />
				{/* <Storybox /> */}
				<ConceptArt />
				<Screenshots />
			</section>
			<section id="do-it-together">
				<Together />
			</section>
			{/* <section id="your-ghost">
				<Ghost />
			</section> */}
		
		
			<Footer />
			<Analytics />
		</>
	);
}
export default GhostLeague;
