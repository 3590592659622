import React from 'react';
import SignupForm from '../SignupForm/SignupForm';
import logo from '../../assets/svg/logos/ghostleague-logo.svg';

const Spaceship = () => (
  <section id="spaceship" className="spaceship section text-center text-white">
    <div className="container">
      
      <article className="mb-4 mx-auto">
        <h2 className="blue">
          WELCOME TO THE YEAR 2250
        </h2>
        <p className="text-white pb-4 text-left glbody">
          Ghost League takes place in a future where human-like AI, nanotechnology, and space colonization are a part of humanity. 
          Entertainment and sports have evolved. Human athletes and AI unite, as companions, in the challenging context of sports. </p>
        <p className="text-left glbody mb-4">
          Humankind is finally among the stars. 
          Military and commercial bases are sprawling throughout the solar system. 
          Aggressive expansion into other solar systems in the galaxy is well underway. 
          Mars serves as a haven for the rich, where they can get away from the tensions of Earth. 
          Entertainment flourishes in a world with few significant human obligations, and the pinnacle of entertainment, is sports. 
          Ghost League is a name on every child's lips, the aspiration of generations, the ultimate trial of sparkour<sup>1</sup> athletes, the ultimate contest of Ghosts. 
          Your story begins as you meet your AI companion in the sparkour dojo. 
          You will embark on a journey to reach the Ghost League, not as a witness, but as a Ghost. </p>
        <p className="text-left small font-weight-lighter pl-5 pr-5">
        1. Sparkour evolved from parkour in the early 22nd century. 
        The new sport allowed athletes to utilize equipment such as grappling hooks, 
        jet boosters, inertial dampeners, effect containers, and suits of exotic materials. 
        Old-fashioned parkour did not allow for added equipment.</p>
      </article>
      <img src={logo} alt="Logo" className="logo mt-4 mb-2" />
      <article className="">
        <h2 className="mb-2">WE ARE GOING TO CREATE A GAME INDUSTRY <span className="blue">REVOLUTION</span></h2>
        <h2>WE NEED TO DO THIS <span className="blue">TOGHETER!</span></h2>

        <div className="col-md-6 offset-md-3 justify-content-center">
          <p className="mb-4">Sign up for our Kickstarter mailing list. You will recieve an <strong>in-game cosmetic reward</strong> as a thank you, when we launch the game.</p>
            <SignupForm tag="submit-4"/>
          </div>
      </article>

    </div>
    
  </section>
);
export default Spaceship;
