import React from 'react';
import DisclaimerModal from '../Modals/DisclaimerModal';
import TermsModal from '../Modals/TermsModal';
import reddit from '../../assets/svg/brands/reddit-alien-brands.svg';
import discord from '../../assets/svg/brands/discord-brands.svg';
import youtube from '../../assets/svg/brands/youtube-brands.svg';

const Footer = () => (
	<footer className="footer">
		<section className="section bg-white text-center">
			<div className="container">
				<h2>Join one of our Communities</h2>
				<p className="lead">
					Our Discord channel is the best place to discuss, share your ideas, and ask questions about Ghost League.
				</p>
				<div className="p-3 mb-4 d-flex justify-content-center">
					<a href="https://www.reddit.com/r/GhostLeague/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Reddit">
						<img className="icon" src={reddit} alt="Reddit" />
					</a>
					<a href="https://discord.gg/z5SEFg6" className="social-icon" target="_blank" rel="noopener noreferrer" title="Discord">
						<img className="icon" src={discord} alt="Discord" />
					</a>
					<a href="https://www.youtube.com/channel/UC1lP8SSilL8MLOnLfHPDwPQ" target="_blank" rel="noopener noreferrer" className="social-icon" title="Youtube">
						<img className="icon" src={youtube} alt="Youtube" />
					</a>
				</div>
				{/*<h2>Don't Want to Miss our Kickstarter Launch?</h2>
				<p className="mb-4 lead">
					Be sure to join our mailing list. We will ONLY email you when the Kickstarter goes live.
				</p>
				<SignupModal
					variant="dark"
					additionalClasses="rounded-pill"
					buttonText="Mailing List" />
					*/}
			</div>
		</section>
		<section className="footer-copyright">
			<div className="p-2 d-flex justify-content-center">
				<p className="text-center text-white m-0">
					Xlayer Studio Copyright &copy; 2020 <DisclaimerModal /> <TermsModal /> <a href="/privacy-policy.html">Privacy Policy</a>
				</p>
			</div>
		</section>
	</footer>
);
export default Footer;

