import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import SignupForm from '../SignupForm/SignupForm';


const SignupModal = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

	return (
		<>
      <Button className={props.additionalClasses} variant={props.variant} onClick={showModal}>
        {props.buttonText}
      </Button>

      <Modal className="signup-modal" show={isOpen} onHide={hideModal}>
        <Modal.Header closeButton>
          <h2>Sign up for our kickstarter</h2>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">Interested in our game?</p>
          <p>Sign up, and get a notification when the kickstarter begins!</p>
          <SignupForm tag="submit-0" />
        </Modal.Body>
      </Modal>
		</>
	);
}
export default SignupModal;
