import React from 'react';

const Screenshots = () => {
  return ( 
    <section id="screenshotRow" className="screenshot-row">
      <div className="container-fluid">
        <div className="row">
          <div className="img1 col-12 col-sm-3" />
          <div className="img2 col-12 col-sm-3" />
          <div className="img3 col-12 col-sm-3" />
          <div className="img4 col-12 col-sm-3" /> 
        </div>
      </div>
    </section>
   );
}
export default Screenshots;
