import React from 'react';
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';

const consentCookie = Cookies.get('CookieConsent')
if(consentCookie !== 'false'){
  // If we get more routes we need to extend this. See: https://levelup.gitconnected.com/using-google-analytics-with-react-3d98d709399b
  ReactGA.initialize('UA-125826360-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
} else {
  Cookies.remove('_gat');
  Cookies.remove('_gid');
  Cookies.remove('_ga');
}


const Analytics = () => {
	return (
		<CookieConsent
      disableStyles={true}
      enableDeclineButton
      flipButtons
      location='none'
      containerClasses='cookie-notice'
      buttonText='No problem!'
      buttonClasses='btn btn-primary btn-sm mr-2'
      declineButtonText="Oh no, you don't!"
      declineButtonClasses='btn btn-dark btn-sm'
		>
      <p>We use anonymous cookies to get an overview of how many visitors we have.</p>
    </CookieConsent>
	);
}
export default Analytics;
